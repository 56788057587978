<template>
    <div :class="[wrapperClass, customClass]" v-if="show">
        <div class="spin-center-container">
            <slot>
                <!--<i :class="iconClass" :style="{color: $vuetify.theme.primary}"></i>-->
                <v-progress-circular
                        :size="35"
                        color="blue lighten-3"
                        indeterminate
                ></v-progress-circular>
                <div v-if="text" class="loading-text mt-4" v-html="text"></div>
            </slot>
        </div>
    </div>
    <!--<transition :name="transitionName" @after-leave="handleAfterLeave">-->

    <!--</transition>-->
</template>

<script>
    import {addClass, removeClass, getStyle} from '@/module/utils/class'

    export default {
        name: 'vSpin',
        data() {
            return {
                transitionName: '',
                onAfterLeave: () => {
                }
            }
        },
        computed: {
            iconClass() {
                return ['dbm-spin', this.icon ? this.icon : 'fa fa-spinner fa-spin']
            },
            wrapperClass(){
                return [ 'v-spin',{
                    'v-spin-wrap':this.mask
                }]
            },

        },
        props: {
            customClass: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
            },
            show: {
                type: Boolean,
                default: true
            },
            mask : {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleAfterLeave() {
                this.onAfterLeave()
                this.$emit('after-leave')
            }
        },
        mounted() {
            let el = this.$el.parentNode
            let originalPosition = getStyle(el, 'position')
            if (originalPosition !== 'absolute' && originalPosition !== 'fixed') {
                addClass(el, 'spin-relative')
            }
        },
        beforeDestroy() {
            removeClass(this.$el.parentNode, 'spin-relative')
        }
    }
</script>

<style lang="scss">

    .v-spin {
        position: absolute;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .spin-center-container {
            text-align: center;
            .dbm-spin {
                font-size: 26px;
            }
            .loading-text {
                font-size: 16px;
                line-height: 30px;
                color: #666;
            }
        }
        &.v-spin-wrap {
            background: rgba(255,255,255, .8);
        }
    }
</style>
